import $ from "jquery";
import popper from "popper.js";
import bootstrap from "bootstrap";
import validate from "jquery-validation";
import slick from "slick-carousel";

$(".table-filters input").on("input", function () {
  filterTable($(this).parents("table"));
});

function filterTable($table) {
  var $filters = $table.find(".filter th");
  var $rows = $table.find("tbody tr");
  $rows.each(function (rowIndex) {
    var valid = true;
    $(this)
      .find("td")
      .each(function (colIndex) {
        if ($filters.eq(colIndex).find("input").val()) {
          if (
            $(this)
              .html()
              .toLowerCase()
              .indexOf(
                $filters.eq(colIndex).find("input").val().toLowerCase()
              ) == -1
          ) {
            valid = valid && false;
          }
        }
      });
    if (valid === true) {
      $(this).css("display", "");
    } else {
      $(this).css("display", "none");
    }
  });
}

async function Fetch(mode) {
  await fetch("https://xn----gtb8afb8e.xn--p1ai/api.php?mode=" + mode)
    .then((response) => response.json())
    .then((res) => console.log(res));
}

$(function () {
  $(".blsliders-ul").slick({
    infinite: false,
    dots: false,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: ".blsliders-list",
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 551,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 300,
        settings: "unslick", // destroys slick
      },
    ],
  });

  $(".blsliders-list").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: ".blsliders-ul",
  });

  $(".button_collapse").click(function (e) {
    if (e.target.textContent.trim() == "Показать все") {
      e.target.textContent = "Скрыть все";
    } else {
      e.target.textContent = "Показать все";
    }
  });

  $(".collspaceInfo-btn").click(function (e) {
    $(".collspaceInfo-block").toggleClass("hidden");
    if (e.target.textContent.trim() == "Показать все") {
      e.target.textContent = "Скрыть все";
    } else {
      e.target.textContent = "Показать все";
    }
  });

  $.validator.setDefaults({
    success: "valid",
  });

  $("#joinTheSystemForm").validate({
    rules: {
      username: {
        required: true,
      },
      contact: {
        required: true,
      },
    },
    messages: {
      username: {
        required: "Обязательно заполнить",
      },
      contact: {
        required: "Обязательно заполнить",
      },
    },
    submitHandler: function (form) {
      let data = $("#" + form.id).serialize();
      $.ajax({
        url: "/api.php",
        type: "get",
        data: data,
        success: function (result) {
          $("#" + form.id)[0].reset();
          $("#joinTheSystem").modal("toggle");
          $("#successSend").modal("show");
        },
      });
      // form.submit();
    },
  });

  //проверка сертификата
  $("#table-form").validate({
    submitHandler: function (form) {
      let data = $("#" + form.id).serialize();
      $.ajax({
        url: "/api.php",
        type: "GET",
        data: data,
        success: function (result) {
          $("#form_result").html(result);
        },
      });
      // form.submit();
    },
  });

  $("#theSurvey").validate({
    rules: {
      name: {
        required: true,
      },
      contact: {
        required: true,
      },
    },
    messages: {
      name: {
        required: "Обязательно заполнить",
      },
      contact: {
        required: "Обязательно заполнить",
      },
    },
    submitHandler: function (form) {
      let data = $("#" + form.id).serialize();
      $.ajax({
        url: "/api.php",
        type: "post",
        data: data,
        success: function (result) {
          $("#successSend").modal("show");
          $("#" + form.id)[0].reset();
        },
      });
      // form.submit();
    },
  });
});

//Слайдер theSurvey
$(function () {
  const sldrItem = document.querySelectorAll(".theSurvey-questions__item");
  const sldrCount = document.querySelector(".theSurvey-contact__qcount");
  const nextList = document.querySelectorAll(
    ".theSurvey-questions__item .next"
  );
  const sldrExit = document.querySelector(".theSurvey-exit");
  let sldrActive = 0;
  nextList.forEach((btn) => {
    btn.onclick = function () {
      sldrItem.forEach((el, i) => {
        if (sldrActive >= sldrItem.length - 1) return false;
        sldrActive === i && el.classList.add("hidden");
        sldrActive++;
        sldrItem[sldrActive].classList.remove("hidden");
        sldrCount.textContent = `Вопрос ${sldrActive + 1} из 2`;
      });
    };
  });

  sldrExit.onclick = function (e) {
    sldrActive = 0;
    sldrCount.textContent = `Вопрос ${sldrActive + 1} из 2`;
    document.querySelector(".theSurvey-success").classList.add("hidden");
    sldrItem.forEach((el, i) => {
      sldrItem[i].classList.add("hidden");
    });
    sldrItem[0].classList.remove("hidden");
  };
});
